// React
import { useContext } from 'react';

// Contexts
import { AppContext } from '../../contexts';

// Components
import MobileMenu from '../MobileMenu';
import Logo from '../Logo';

// Icons
import { MdLightMode, MdDarkMode } from 'react-icons/md';
import CoinIcon from '../CoinIcon';

const Navbar = props => {

  const { theme, setTheme } = useContext(AppContext);

  const changeTheme = e => setTheme( e.target.checked ? 'light' : 'dark' );

  return (
    <nav className="navbar">
      <div className="left">
        <a className="brand" href="/">
          <Logo />
          <span className="title">Maine&nbsp;Coon</span>
        </a>
      </div>
      <div className="right">

        <MobileMenu />

        <nav>
          <ul className="links">
            <li><a href="#">Ecosystem</a></li>
            <li><a href="#">Buy</a></li>
            <li><a href="#">MainCoonSwap</a></li>
            <li><a href="#">Art</a></li>
            <li><a href="#">Rescue</a></li>
            <li><a href="#">Community</a></li>
            <li>
              <label className="switch">
                <input type="checkbox" onChange={changeTheme} checked={theme === 'light'} />
                <span className="slider round"></span>
                <span className={`icon ${theme === 'light' ? 'left' : 'right'}`}>
                  {theme === 'dark' && <MdDarkMode size={22} className="icon" />}
                  {theme === 'light' && <MdLightMode size={22} className="icon" />}
                </span>
              </label>
            </li>
            <li>
              <a className="button" href="#">
                <CoinIcon className="icon" color={theme === 'light' ? '#ff2946' : '#ffc629'} />
                <span>Get&nbsp;Main&nbsp;Coon</span>
              </a>
            </li>
          </ul>

        </nav>
      </div>
    </nav>
  )
}

export default Navbar;