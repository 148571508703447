// Components
import { Navbar } from '../../components';
import Hero from './Hero';

function Home() {
  return (
    <>
      <Navbar />
      <div className="container">
        <Hero />
      </div>
    </>
  )
}

export default Home;