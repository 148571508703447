// React
import React from 'react';
import ReactDOM from 'react-dom';

// CSS
import './css/main.css';

// Components
import App from './App';

// Service Worker
import * as serviceWorker from './serviceWorker';

// ----------------------------------------------------------------------

ReactDOM.render(<App />, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
