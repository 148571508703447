const CoinIcon = ({ color = '#ffc629', ...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <defs>
        <style>{`.prefix__cls-1{fill:${color}}`}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          className="prefix__cls-1"
          d="M212.39 131.24a41.19 41.19 0 014.31.24l-26.76-56.32-3.94 65.33a42.61 42.61 0 0126.39-9.25zM299.61 131.24a42.61 42.61 0 0126.39 9.25l-3.9-65.33-26.8 56.32a41.19 41.19 0 014.31-.24zM131.94 202c.21 0 .42-.08.64-.12l-30.13-38.67 8.22 51.34A40 40 0 01131.94 202zM139.97 213.02v-1.7 1.7zM380.06 202a40 40 0 0121.27 12.55l8.22-51.34-30.13 38.67c.21-.01.43.02.64.12zM255.34 394.32c-8.89 0-19 6.84-31.58 11.4s-63.58 17.95-73.49-31.61 31.06-56.83 41-73.35 27.09-50.22 64.1-50.22 54.18 33.7 64.1 50.22 50.88 23.79 41 73.35-61 36.17-73.49 31.61-22.75-11.4-31.64-11.4zM212.39 143.17c20.43 0 37 21.26 37 47.49s-16.57 47.49-37 47.49-37-21.26-37-47.49 16.57-47.49 37-47.49M299.61 143.17c20.44 0 37 21.26 37 47.49s-16.57 47.49-37 47.49-37-21.26-37-47.49 16.57-47.49 37-47.49M140 213c16.23 0 31.76 14.79 36.22 35.65 5 23.22-5.69 45.19-23.79 49.06a26.74 26.74 0 01-5.54.58c-16.23 0-31.76-14.78-36.22-35.64-5-23.23 5.69-45.19 23.79-49.06A26.09 26.09 0 01140 213M372 213a26.09 26.09 0 015.54.59c18.1 3.87 28.75 25.83 23.79 49.06-4.46 20.86-20 35.64-36.22 35.64a26.74 26.74 0 01-5.54-.58c-18.1-3.87-28.75-25.84-23.79-49.06C340.27 227.81 355.8 213 372 213"
        />
        <path
          className="prefix__cls-1"
          d="M255.21 51.57a205.21 205.21 0 01145.11 350.32 205.21 205.21 0 11-290.21-290.21 203.86 203.86 0 01145.1-60.11m0-50C114.26 1.57 0 115.84 0 256.79S114.26 512 255.21 512s255.22-114.26 255.22-255.21S396.16 1.57 255.21 1.57z"
        />
      </g>
    </svg>
  )
}

export default CoinIcon;