// React
import React, { useState, useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// Contexts
import { AppContext } from './contexts';

// Pages
import {
  Home,
} from './pages';


// ----------------------------------------------------------------------

const App = ()=> {

  const [ theme, setTheme ] = useState('dark');

  useEffect(()=> {

    (async()=>{

      const data = await fetch("/api/getCurrentSupply", {
        "method": "POST",
        "headers": { "content-type": "application/json" },
        "body": JSON.stringify({ phrase: 'what' })
      });

      const { response } = await data.json();

      console.log({ response })

    })()

  },[]);

  useEffect(()=> {
    const currentTheme = localStorage.getItem('theme');
    if (!currentTheme) localStorage.setItem( 'theme', theme );
    setTheme( currentTheme || theme );
  }, []);

  useEffect(()=> {
    document.documentElement.classList.forEach( key => document.documentElement.classList.remove(key) );
    document.documentElement.classList.add(theme);
    document.body.classList.forEach(key => document.body.classList.remove(key));
    document.body.classList.add(theme);
  }, [theme]);

  const appContext = useMemo(()=> ({ theme,
    setTheme: themeName => {
      setTheme(themeName);
      localStorage.setItem('theme', themeName);
    },
  }));

  return (
    <AppContext.Provider value={appContext}>
      <Router>

        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>

      </Router>
    </AppContext.Provider>
  );
}

// ----------------------------------------------------------------------

export default App;