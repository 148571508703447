const Hero = ()=> {
  return (
    <div className="home-hero">
      <div className="home-hero-body">
        <h1 className="home-hero-title">The ultimate alternative to the dog. The 'Maine' Cat is now out of the bag.</h1>
        <p className="home-hero-subtitle">
          ROAR - A meme token fit for market competition.
        </p>
        <div className="home-hero-cta">
          <a className="btn primary">Get ROAR</a>
          <button className="btn secondary">Read RoarPaper v1</button>
          <button className="btn">About</button>
        </div>
      </div>
    </div>
  )
}

export default Hero;