// React
import { useRef, useState, useContext } from 'react';

// Contexts
import { AppContext } from '../../contexts';

// Hooks
import { useOnClickOutside } from '../../hooks';

// Components
import Logo from '../Logo';

// Icons
import { MdLightMode, MdDarkMode } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';
import { FaBars } from 'react-icons/fa';

import CoinIcon from '../CoinIcon';

const MobileMenu = ()=> {

  const [ open, setOpen ] = useState(false);

  const menuRef = useRef();
  useOnClickOutside(menuRef, ()=> setOpen(false));

  const { theme, setTheme } = useContext(AppContext);
  const changeTheme = e => setTheme(e.target.checked ? 'light' : 'dark');

  const openMenu = e => setOpen(true);
  const closeMenu = e => setOpen(false);

  return open ? (
    <nav className="mobile-menu">
      <div className="menu-container" ref={menuRef}>
        <ul className="links-mobile">
          <li><a onClick={closeMenu} href="#">Ecosystem</a></li>
          <li><a onClick={closeMenu} href="#">Buy</a></li>
          <li><a onClick={closeMenu} href="#">MainCoonSwap</a></li>
          <li><a onClick={closeMenu} href="#">Art</a></li>
          <li><a onClick={closeMenu} href="#">Rescue</a></li>
          <li><a onClick={closeMenu} href="#">Community</a></li>
          <li>
            <label className="switch">
              <input type="checkbox" onChange={changeTheme} checked={theme === 'light'} />
              <span className="slider round"></span>
              <span className={`icon ${theme === 'light' ? 'left' : 'right'}`}>
                {theme === 'dark' && <MdDarkMode size={22} className="icon" />}
                {theme === 'light' && <MdLightMode size={22} className="icon" />}
              </span>
            </label>
          </li>
          <li>
            <a className="button" href="#">
              <CoinIcon className="icon" color={theme === 'light' ? '#ff2946' : '#ffc629'} />
              <span>Get&nbsp;Main&nbsp;Coon</span>
            </a>
          </li>
        </ul>
      </div>
      <button className="close" onClick={closeMenu}>
        <AiFillCloseCircle className="icon" size={42}/>
      </button>
    </nav>
  ) : (
    <button className="mobile-menu-open" onClick={openMenu}>
      <FaBars className="icon" size={36} />
    </button>
  )
}

export default MobileMenu;